import React from 'react'

function BulletItem({ title, children }) {
    return (
        <div className="bullet-item">
            <div className="bullet-item__title">{title}</div>
            {children}
        </div>
    )
}

export default BulletItem