import React from 'react';
import Article from '../Article';
import { Section, Subsection } from '../Section';

function OurServices() {
    return (
        <Article title="השירותים שלנו" color="blue">
            <Section title="הפתרונות והשירותים שלנו">
                <p>
                אבן דרך מספקת שירותי ייעוץ, תכנון, פיתוח, בדיקות, יישום והטמעה של פרויקטי מערכות מידע לרבות ניתוח תובנות עסקיות וחיזוי באמצעות ML ו-AI למגזרים המוניציפאליים והממשלתיים. 
                אבן דרך עובדת עם הלקוחות הגדולים ביותר בשוק המוניציפאלי – עיריית תל-אביב ועיריית ירושלים בפרויקטים מורכבים של העברת פתרונות תוכנה ברמה הבינעירונית, שדרוג תשתיות תוכנה, הטמעת מערכות והטמעת מתודולוגיות עבודה חדשניות בצוותי הפיתוח.
                אבן דרך עובדת עם השחקנים הגדולים ביותר בשוק התוכנה, מטריקס ונס בליווי מקצועי של פרויקטים, שירותי השמה טכנולוגיים ומתן שירותי ערך מוסף ברישוי פתרונות.
                הפרויקטים שאבן דרך אוהבת להשתתף בהם הנם פרויקטים חברתיים, אשר מסייעים לקהילה, למשל מערכות רווחה, מערכות חרום ועוד.
                אבן דרך מפיצה של פתרונות תוכנה אשר מאפשרים תקשורת של לקוחות ממגוון הערוצים הדיגיטליים בצורה פשוטה בממשק אחד פשוט לתפעול על-ידי הרפרנטים המטפלים.
                אבן דרך מספקת פתרונות גבייה רוחביים, וקליטת טפסים בצורה פשוטה ומיטבית הן ללקוח והן לרפרנט המטפל. 
                להלן מפורטים השירותים שלנו לפי קטגוריה:
                </p>

                <Subsection title="Big Data & BI">
                    <p>
                        בנייה ותחזוקה של פתרונות ביג דאטה בענן או על גבי תשתיות מקומיות. 
                    </p>
                </Subsection>

                <Subsection title="Web & Mobile">
                    <p>
                        פיתוח אפליקציות ארגוניות ולצרכנים, למכשירים ניידים וכאתרים, בנייה ותחזוקה של פתרונות ביג דאטה בענן או על גבי תשתיות מקומיות.
                    </p>
                </Subsection>

                <Subsection title="הקמה הסבה ותחזוקה של מערכות ליבה עסקית">
                    <p>
                        סיוע בפיתוחים והסבות של מערכות ליבה עסקיות כגון Mainframe ומערכות Power בשיתוף עם חברת IBM.
                    </p>
                </Subsection>

                <Subsection title="AI & ML">
                    <p>
                        פיתוח מודלים לניבוי ושיפור ביצועים עסקיים וחווית לקוח מותאמת אישית.
                    </p>
                </Subsection>

                <Subsection title="IoT & Devices">
                    <p>
                        פיתוח מוצרי embedded לאורך מחזור החיים של המוצר - NPI לרבות העברה לייצור ובקרת איכות בהתאם לתקנים המתאימים.
                    </p>
                </Subsection>

                <Subsection title="ייעוץ ארגוני, עסקי ואסטרטגי">
                    <p>
                        ייעוץ וליווי מקצועי מהגדרת יעדים ומטרות, בניית תוכניות עסקיות, הטמעה, הדרכה, רישוי למוצרים ואופטימיזית תמחור בהתאם לצרכים.
                    </p>
                </Subsection>
            </Section>

        </Article>
    )
}

export default OurServices