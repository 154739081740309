import { Collapse } from 'bootstrap';
import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import FacebookIcon from "../assets/facebook-icon.svg";
import LinkedinIcon from "../assets/linkedin-icon.svg";
import Logo from "../assets/logo.svg";
import TwitterIcon from "../assets/twitter-icon.svg";

function Header() {

    const closeMainNav = () => {
        const collapseElementList = document.querySelectorAll('.collapse')
        if (!collapseElementList.length) {
            return;
        }

        Collapse.getInstance(collapseElementList[0]).hide()
    };

    useEffect(() => {
        const collapseElementList = document.querySelectorAll('.collapse')
        const collapseList = [...collapseElementList].map(
            collapseEl => new Collapse(collapseEl, { toggle: false })
        );
    }, [])

    return (
        <div className="header">
            <div className="container">
                <div className="navbar navbar-expand-lg navbar-dark align-items-lg-end">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#main-nav" aria-controls="main-nav"
                        aria-expanded="false" aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>


                    {/* Menu */}
                    <div className="main-nav collapse navbar-collapse" id="main-nav">
                        <ul className="navbar-nav ms-auto p-0">
                            <li className="nav-item mt-3 mt-lg-0">
                                <NavLink className="nav-link" to="/about" onClick={closeMainNav}>אודות</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/our-services" onClick={closeMainNav}>השירותים שלנו</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="our-customers" onClick={closeMainNav}>הלקוחות שלנו</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/jobs" onClick={closeMainNav}>משרות פתוחות</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/contact-us" onClick={closeMainNav}>צרו קשר</NavLink>
                            </li>
                        </ul>

                        <div className="social-links">
                            <a className="social-links__item" href="https://www.facebook.com/evenderechit"><img src={FacebookIcon} alt="facebook" /></a>
                            <a className="social-links__item" href="https://twitter.com/evenderechit"><img src={TwitterIcon} alt="twitter" /></a>
                            <a className="social-links__item" href="https://www.linkedin.com/in/uriberman/"><img src={LinkedinIcon} alt="linkedin" /></a>
                        </div>
                    </div>

                    {/* Logo */}
                    <div className="header-logo">
                        <Link to="/"><img src={Logo} alt="logo" onClick={closeMainNav}/></Link>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Header