import { Outlet, Route, Routes } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import About from './pages/About';
import CategoryJobs from "./pages/CategoryJobs";
import ContactUs from "./pages/ContactUs";
import Home from './pages/Home';
import Jobs from "./pages/Jobs";
import OurCustomers from "./pages/OurCustomers";
import OurServices from './pages/OurServices';

function App() {

    return (
        <div>

            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/our-services" element={<OurServices />} />
                    <Route path="/our-customers" element={<OurCustomers />} />
                    <Route path="/jobs/:id" element={<CategoryJobs />} />
                    <Route path="/jobs" element={<Jobs />} />
                    <Route path="/contact-us" element={<ContactUs />} />

                    <Route path="*" element={<h1>נתיב לא קיים</h1>} />
                </Route>
            </Routes>
        </div>
    );
}

function Layout() {
    return (
        <>
            {/* Header */}
            <Header />

            {/* Content Layout */}
            <Outlet />

            {/* Footer */}
            <Footer />
        </>
    );
}


export default App;

