import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import jobsCategories from '../../data/JobsCategories';
import Article from '../Article'
import { Section, Subsection } from '../Section'

function CategoryJobs() {

    // get category
    let { id } = useParams();

    const [category, setCategory] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        // setCategory(jobsCategories.find(item => {
        //     return item.id === 9;
        // }));
        setCategory({ title: "משרות עבורך" });


        fetch(`https://wp.even-derech-it.com/wp-json/wp/v2/posts/?categories=${id}&acf_format=standard`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoading(false);
                    setJobs(result);
                },

                (error) => {
                    setIsLoading(false);
                    setError(error);
                }
            )
    }, [])

    return (
        <Article title="משרות פתוחות" color="yellow">
            {category && <Section title={category.title}></Section>}

            {/* Spinner */}
            {isLoading && <div className="h6 mt-3">משרות נטענות...</div>}

            {/* Jobs list */}
            {jobs && !error && !isLoading && jobs.map(job => {

                // The data from the acl
                const jobData = job.acf;

                return (
                    <div key={job.id} className="job">
                        <Subsection title={jobData.title}>
                            {jobData.sub_title}
                        </Subsection>

                        <Subsection title="תיאור">
                            {jobData.description}
                        </Subsection>

                        <Subsection title="דרישות התפקיד">
                            <div dangerouslySetInnerHTML={{ __html: jobData.requirements }}></div>
                        </Subsection>

                        <Subsection title="מיקום">
                            {jobData.position}
                        </Subsection>
                        <Subsection title="סיווג">
                            {job.slug}
                        </Subsection>
                        <Subsection title="מספר משרה">
                            {jobData.job_number}
                        </Subsection>
                    </div>
                )
            })
            }
        </Article>
    )
}

export default CategoryJobs