import React from 'react';
import { NavLink } from 'react-router-dom';
import GrayLines from '../../../assets/gray-lines.svg';
import homeServices from '../../../data/homeServices';
import BulletItem from '../../BulletItem';

function HomeServices() {

    return (
        <div className="home-section py-3 py-lg-5 mt-lg-5 bg-gray-200">
            <div className="container">
                <div className="d-md-flex gap-3">
                    <div className="home-section__image">
                        <img src={GrayLines} alt="gray lines" className="img-fluid" />
                    </div>

                    <div className="flex-1">
                        <div className="home-section-title mb-4">
                            <div className="h3 m-0 text-primary-purple">השירותים שלנו</div>
                            מגוון השירותים שלנו יסייעו לארגונכם לממש את מטרותיו ויעדיו
                        </div>

                        <div className="row g-3 g-lg-5">
                            {homeServices.map((item) =>
                                <div key={item.id} className="col-12 col-lg-6">

                                    <BulletItem title={item.title}>
                                        <div>{item.body}</div>
                                    </BulletItem>

                                </div>
                            )}
                        </div>

                        <NavLink className="btn btn-primary-purple mt-4 px-4" to="/our-services">רוצים לדעת עוד?</NavLink>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default HomeServices