import React from 'react';
import EnvelopeMiniIcon from "../assets/envelope-mini-icon.svg";
import LogoHeart from "../assets/logo-heart.svg";
import LogoText from "../assets/logo-text.svg";
import PhoneMiniIcon from "../assets/phone-mini-icon.svg";
import PinMiniIcon from "../assets/pin-mini-icon.svg";
import ContactForm from "./ContactForm";

function Footer() {
    return (
        <div className="footer">
            <div className="container">
                <div className="footer-box">

                    {/* About Us */}
                    <div className='px-lg-5'>
                        <ContactForm location="footer" />
                    </div>

                    {/* Contact Box */}
                    <div className='pt-5 pt-lg-0 px-lg-5 mx-auto'>
                        <div className="contact-box">
                            <span><img src={LogoText} alt="logo-text" /></span>
                            <span><img src={LogoHeart} alt="logo-heart" className="footer-heart" /></span>

                            <span>02-587-6867</span>
                            <span><img src={PhoneMiniIcon} alt="phone-icon" /></span>

                            <span><a className="mail-link" href="mailto:info@even-derech-it.awsapps.com">info@even-derech-it.awsapps.com</a></span>
                            <span><img src={EnvelopeMiniIcon} alt="mail-icon" /></span>

                            <span>מבוא המתמיד 16, ירושלים, 9459307</span>
                            <span><img src={PinMiniIcon} alt="location-icon" /></span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Footer