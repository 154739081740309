import React from 'react'

function Section({ title, children }) {
    return (
        <section className="section">
            <div className="section__title" dangerouslySetInnerHTML={{__html: title}}></div>
            {children}
        </section>
    )
}

function Subsection({ title, children }) {
    return (
        <>
            <div className="section__subtitle">{title}</div>
            {children}
        </>
    )
}

export { Section, Subsection }
