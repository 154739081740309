import React from 'react'
import Article from '../Article'
import ContactForm from '../ContactForm'

function ContactUs() {
    return (
        <Article title="צרו קשר">

            <dl className="contact-details">
                <dt>כתובת:</dt>
                <dd>מבוא המתמיד 16, ירושלים, 9459307</dd>

                <dt>דואר אלקטרוני:</dt>
                <dd><a href="mailto:info@even-derech-it.awsapps.com">info@even-derech-it.awsapps.com</a></dd>

                <dt>טלפונים:</dt>
                <dd>
                    <a className="phone-link" href="tel:+972-(0)2-025876867">+972-(0)2-587-6867</a>
                    <br />                    
                    <a className="phone-link" href="tel:+972-(0)54-204-5454">+972-(0)54-204-5454</a>
                    <br />
                    <a className="phone-link" href="tel:+972-(0)50-343-0550">+972-(0)50-343-0550</a>
                </dd>
            </dl>

            <div className="mt-5">
                <ContactForm />
            </div>
        </Article>
    )
}

export default ContactUs