const jobsCategories = [
    {
        id: 9,
        title: "משרות בתחום התשתיות",
        description: "DBA, System, Networking, Security"
    },
    {
        id: 8,
        title: "משרות בתחום פיתוח WEB & Mobile",
        description: "Client, Server, Fullstack, Mobile Apps"
    },
    {
        id: 7,
        title: "משרות בתחומי ניהול ניתוח ותכנון",
        description: "ניהול פרויקטים, ניתוח מערכות, ארכיטקטורה"
    },
    {
        id: 4,
        title: "משרות בתחום IoT וה-Devices",
        description: "פיתוח embedded, עיצוב מוצר, הבאה לייצור, ניהול הייצור"
    },
    {
        id: 5,
        title: "משרות בתחום האנליטיקה, נתונים, AI, ML, BI",
        description: "ניתוח וטיוב נתונים, עיצוב מודולים, פיתוח דשבורדים, פיתוח ומימוש מודלים ורשתות נוירוניות"
    },
    {
        id: 6,
        title: "משרות בתחום הבדיקות והאיכות",
        description: "בדיקות ידניות, אוטומטיות ניהול QA ו-QC"
    },
];



export default jobsCategories;