import { Link } from 'react-router-dom';
import BulletItem from '../../BulletItem';
import jobsCategories from '../../../data/JobsCategories'

function JobsCategoriesList() {

    return (
        <div className="row g-3 g-lg-5">
            {jobsCategories.map((jobCategory) =>
                <div key={jobCategory.id} className="col-12 col-md-6 col-xl-4">

                    <BulletItem title={jobCategory.title}>
                        <div>{jobCategory.description}</div>
                        <Link to={"/jobs/" + jobCategory.id}>למידע נוסף</Link>
                    </BulletItem>

                </div>
            )}
        </div>
    )
}

export default JobsCategoriesList;