const homeServices = [
    { 
        id: 1, 
        title: "Big Data & BI",
        body: "בנייה ותחזוקה של פתרונות ביג דאטה בענן או על גבי תשתיות מקומיות"
    },
    { 
        id: 2, 
        title: "AI & ML",
        body: "פיתוח מודלים לניבוי ושיפור ביצועים עסקיים וחווית לקוח מותאמת אישית"
    },
    { 
        id: 3, 
        title: "Web & Mobile",
        body: "פיתוח אפליקציות ארגוניות ולצרכנים, למכשירים ניידים וכאתרים"
    },
    { 
        id: 4, 
        title: "IoT & Devices",
        body: "פיתוח מוצרי embedded לאורך מחזור החיים של המוצר - NPI לרבות העברה לייצור ובקרת איכות בהתאם לתקנים המתאימים"
    },
    { 
        id: 5, 
        title: "הקמה הסבה ותחזוקה של מערכות ליבה עסקית",
        body: "סיוע בפיתוחים והסבות של מערכות ליבה עסקיות כגון Mainframe ומערכות Power"
    },
    { 
        id: 6, 
        title: "ייעוץ ארגוני, עסקי ואסטרטגי",
        body: "ייעוץ וליווי מקצועי מהגדרת יעדים ומטרות, בניית תוכניות עסקיות, הטמעה והדרכה"
    },
];

export default homeServices;