import React from 'react'
import Article from '../Article'
import { Section } from '../Section'

function About() {
    return (
        <Article title="אודות" color="red">

            <Section title="אני מאמין">
                <p>
                    בחוזקה של הקהילה לנצח אתגרים חדשים.
                    <br />
                    בשיפור מתמיד של תהליכים באמצעות שילוב של טכנולוגיה וההון האנושי. 

                    <br />
                    בחוויה מנצחת לכל בעלי העניין בפרויקטים שאותם אנו מובילים, הניצחון הוא משותף לכולם.
                    <br />                    
                    שקיפות פרויקטלית אשר תחזק את הביטחון שלכם בפרויקט ובאיכות ביצועו בכל שלב ושלב.

                    <br />
                    אנו הקבוצה המובילה בישראל לעולם שירותי מערכות המידע והטכנולוגיה.
                    כחברה עם למעלה מעשרות שנות ניסיון מצטבר בניהול נכון ומוצלח של פרויקטים מהגדולים והמורכבים שנעשו בישראל, אבן דרך מבינה את המשמעות של להיות חברת פרויקטים טכנולוגיים מובילה החל מרמת המקצועיות, דרך הטמעת תהליכי איכות ומתודולוגיות מורכבות, ניהול סיכונים, הכשרת כוח אדם מיומן המשלב הבנה עסקית וידע טכנולוגי מתקדם וכלה במסירת הפרויקט בזמן ולשביעות רצון הלקוח.
                </p>
                <p>

                </p>
            </Section>

            <Section title="המייסדים">
                <p>
                    <strong>אורי ברמן</strong>
                    <br />
                בעל ניסיון של מעל לשלושה עשורים בתחומי הטכנולגיה, תשתיות, תקשורת, אבטחת מידע, אפיון, ארכיטקטורה של מערכות מידע, פיתוח בשפות מרובות
                , עבודה עם בסיסי נתונים מסוג sql ו-nosql.
                בעל ניסיון רב בתחום האיכות ובדיקות תוכנה, בניית נהלים ותהליכי עבודה.
                בעל ניסיון רב בבניית צוותים מסוגים שונים לטיפוסי פרויקטים שונים בהיקפים של עשרות מליוני שקלים, משמש כמרצה מעל לשני עשורים במגוון מוסדות אקדמיים בכל תחומי עיסוקו. 
                <br />
                אורי אוהב linux, python וללכת לים.                
                
                </p>
                <p>
                    <strong>עוזי אמור</strong>
                    <br />
                בעל ניסיון של כשלושה עשורים בתחום מערכות ה-IT, ניסיון בתקשורת , טכנאות מחשבים, סיסטם, מערכות power ומערכות as400 תפקידים ניהוליים בצוותים של עשרות אנשים. 
                <br />
                עוזי אוהב טכנולוגיה וניו-מדיה.
                </p>
            </Section>

            <Section title="איך אנחנו עובדים">
                <p>
                כקבוצה מובילה בשוק ה-IT אנו באבן דרך, שמים דגש רב על גיוס עובדים איכותיים ומקצועיים לשורותינו.

                    בהתאם לכך, אנו מציעים מגוון משרות ותפקידים בתחומים שונים ובעולמות התוכן הטכנולוגיים המתקדמים לרבות:
                    <br />
                    <ul>
                    <li>תוכנה</li>
                    <li>מערכות מידע</li>
                    <li>BI, Big Data, Data Lake</li>
                    <li>AI\ML</li>
                    <li>DEVOPS</li>
                    <li>סייבר ואבטחת מידע</li>
                    <li>תשתיות ותמיכה</li>
                    <li>פיתוח הדרכה</li>
                    <li>שירות ומכירות</li>
                    <li>QA</li>
                    </ul>
                    
                    באבן דרך ישנו צוות גיוס אשר מלווה את המועמדים לאורך כל תהליך הגיוס ומהווה כגורם המקשר בין המועמדים למנהלים המקצועיים. צוות מקצועי מלווה את העובד וזמין עבורו עם הצעות לפתרון תוך 12 שעות מפתיחת פניה. 

                </p>
                <p>
                </p>
            </Section>

        </Article>
    )
}

export default About