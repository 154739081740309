import React from 'react';
import ColoredLines from "../assets/colored-lines.svg";

function Article({ title, color, children }) {


    return (
        <article className={'article' + (color !== undefined ? ' article--primary-' + color : "")}>

            <header className="article__header">
                <div className="container">
                    <h1 className="article__title">{title}</h1>
                </div>
            </header>

            <img src={ColoredLines} alt="colored lines" className="lines-decorated" />
            <div className="container">
                <div className="article__content">
                    {children}
                </div>
            </div>
        </article>
    )
}


export default Article