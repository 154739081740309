import React from 'react';
import ColoredLines from "../../../assets/colored-lines.svg";
import JobsCategoriesList from '../jobs/JobsCategoriesList';

function HomeJobs() {
    return (
        <div className="home-section py-3 py-lg-5 mt-lg-5">
            <div className="container">

                <div className="d-md-flex align-items-streatch gap-5">
                    <div className="home-jobs-lines">
                        <img src={ColoredLines} alt="colored lines" />
                    </div>
                    <div className="py-lg-4">
                        <div className="home-section-title mb-5 mb-sm-4">
                            <div className="h3 m-0 text-primary-purple">לעבוד באבן דרך</div>
                            אנו מזמינים אותך להצטרף למשפחת ״אבן דרך״ ולהשתתף בפרויקטים בעלי משמעות חברתית ומקצועית.
                        </div>

                        <JobsCategoriesList />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default HomeJobs