import React, { useState } from 'react'

function ContactForm({ location }) {

    const [fullName, setFullName] = useState('');
    const [company, setCompany] = useState('');
    const [postion, setPostion] = useState('');
    const [cellPhone, setCellPhone] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [comments, setComments] = useState('');

    function handleSubmit(event) {
        // add validation here if needed
        // alert(`Form data submited: \nfullName: 
        //     ${fullName}, \ncellPhone: ${company}, \nemail: ${postion},
        //     ${cellPhone}, \ncellPhone: ${email}, \nemail: ${subject},
        //     ${comments}
        // `);
        // todo: connect to monday
        alert('פנייתך נשלחה, נחזור אליך בהקדם')
        // event.preventDefault();
    }

    return (
        <form onSubmit={handleSubmit}>

            <div className="row">
                {/* row 1 */}
                <div className="mb-4 col-12 col-lg-4">
                    <label htmlFor="full-name" className="form-label visually-hidden">שם מלא*</label>
                    <input type="text"
                        className="form-control"
                        id="full-name"
                        name="full-name"
                        placeholder="שם מלא*" required
                        value={fullName}
                        onChange={e => setFullName(e.target.value)}
                    />
                </div>

                <div className="mb-4 col-12 col-lg-4">
                    <label htmlFor="company" className="form-label visually-hidden">חברה*</label>
                    <input type="text"
                        className="form-control"
                        id="company"
                        name="company"
                        placeholder="חברה*" required
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                    />
                </div>

                <div className="mb-4 col-12 col-lg-4">
                    <label htmlFor="postion" className="form-label visually-hidden">תפקיד</label>
                    <input type="text"
                        className="form-control"
                        id="postion"
                        name="postion"
                        placeholder="תפקיד"
                        value={postion}
                        onChange={e => setPostion(e.target.value)}
                    />
                </div>

                {/* row 2 */}
                <div className="mb-4 col-12 col-lg-4">
                    <label htmlFor="cell-phone" className="form-label visually-hidden">נייד*</label>
                    <input type="tel"
                        className="form-control"
                        id="cell-phone"
                        name="cell-phone"
                        placeholder="נייד*" required
                        value={cellPhone}
                        onChange={e => setCellPhone(e.target.value)}
                    />
                </div>

                <div className={'mb-4 col-12 ' + (location === "footer" ? " col-lg-4" : "col-lg-8")}>
                    <label htmlFor="email" className="form-label visually-hidden">דואר אלקטרוני</label>
                    <input type="email"
                        className="form-control"
                        id="email" name=
                        "email"
                        placeholder="דואר אלקטרוני"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>

                <div className={'mb-4 col-12' + (location === "footer" ? " col-lg-4" : "")}>
                    <label htmlFor="subject" className="form-label visually-hidden">נושא</label>
                    <input type="text"
                        className="form-control"
                        id="subject"
                        name="subject"
                        placeholder="נושא"
                        value={subject}
                        onChange={e => setSubject(e.target.value)}
                    />
                </div>

                {/* row 3 */}
                {(location !== "footer") &&
                    <div className="mb-4 col-12">
                        <label htmlFor="comments" className="form-label visually-hidden">הערות</label>
                        <textarea rows="7"
                            className="form-control"
                            id="comments"
                            name="comments"
                            placeholder="הערות"
                            value={comments}
                            onChange={e => setComments(e.target.value)}
                        ></textarea>
                    </div>
                }
            </div>

            <div className="row">
                <div className="col-12 col-sm-4">
                    <button type="submit" className="btn btn-primary-green w-100">שלח/י</button>
                </div>
            </div>
        </form>
    )
}

export default ContactForm