import React from 'react'
import Article from '../Article'
import { Section } from '../Section'
import JerusalemLogo from "../../assets/customers/jerusalem-logo.png"
import TelAvivLogo from "../../assets/customers/tel-aviv-logo.png"
import NessLogo from "../../assets/customers/ness-logo.png"
import GadotLogo from "../../assets/customers/gadot-logo.png"

function OurCustomers() {

    const logos = [
        { id: "jerusalem", file: JerusalemLogo },
        { id: "tel-aviv", file: TelAvivLogo },
        { id: "ness", file: NessLogo },
        { id: "gadot", file: GadotLogo },
    ]

    return (
        <Article title="הלקוחות שלנו" color="green">
            <Section title="סיפורי הצלחה בהמשכים">
                <p>
                אבן דרך מתמקדת בעבודה עם השוק המוניציפאלי ועובדת עם הלקוחות הגדולים ביותר בשוק זה – עיריית תל-אביב ועיריית ירושלים. אבן דרך מובילה פרויקטים מורכבים של העברת פתרונות תוכנה ברמה הבינעירונית, שדרוג תשתיות תוכנה, הטמעת מערכות והטמעת מתודולוגיות עבודה חדשניות בצוותי הפיתוח.
                אבן דרך עובדת עם השחקנים הגדולים ביותר בשוק התוכנה, מטריקס ונס בליווי מקצועי של פרויקטים, שירותי השמה טכנולוגיים ומתן שירותי ערך מוסף ברישוי פתרונות.
                בנוסף אבן דרך עובדת עם חברות הזנק ישראליות כדי להביא ערך לעולם ההיי-טק הישראלי ולחבר בינו לבין העולמות המוניציפאליים.
                </p>
            </Section>

            <div className="row align-items-stretch">
                {logos.map((logo) =>
                    <div key={logo.id} className="col-6 col-xl-4 col-xxl-3">
                        <div className="h-100 d-flex align-items-center">
                            <img src={logo.file} alt={logo.id} className="img-fluid" />
                        </div>
                    </div>
                )}
            </div>
        </Article>

    )
}

export default OurCustomers