import React from 'react'
import Article from '../Article'
import { Section } from '../Section'
import JobsCategoriesList from './jobs/JobsCategoriesList'

function Jobs() {

    return (
        <Article title="משרות פתוחות" color="yellow">
            <Section title=" אנו מזמינים אותך להצטרף למשפחת ״אבן דרך״
                    <br />
                    ולהשתתף בפרויקטים בעלי משמעות חברתית ומקצועית.">
                <p>

                כקבוצה מובילה בשוק ה-IT אנו באבן דרך, שמים דגש רב על גיוס עובדים איכותיים ומקצועיים לשורותינו.

בהתאם לכך, אנו מציעים מגוון משרות ותפקידים בתחומים שונים ובעולמות התוכן הטכנולוגיים המתקדמים לרבות הרשימה המופיעה להלן.
< br />

באבן דרך ישנו צוות גיוס אשר מלווה את המועמדים לאורך כל תהליך הגיוס ומהווה כגורם המקשר בין המועמדים למנהלים המקצועיים. צוות מקצועי מלווה את העובד וזמין עבורו עם הצעות לפתרון תוך 12 שעות מפתיחת פניה. התנאים שלנו כוללים הכנה למבחני לקוח, הכשרה לאורך העבודה ותנאים סוציאליים מלאים.
</p>
            </Section>

            <JobsCategoriesList />
        </Article>
    )
}

export default Jobs