import React from 'react'
import HomeBanner from './home/HomeBanner'
import HomeJobs from './home/HomeJobs'
import HomeServices from './home/HomeServices'

function Home() {
    return (
        <>
            <HomeBanner />
            <HomeServices />
            <HomeJobs />
        </>
    )
}

export default Home